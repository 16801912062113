import React, { useMemo, useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import { I18n } from '@aws-amplify/core';
import {
	MeasuringStrategy,
	closestCorners,
	DragStartEvent,
	DragOverEvent,
	PointerSensor,
	DragEndEvent,
	TouchSensor,
	MouseSensor,
	DragOverlay,
	DndContext,
	useSensors,
	useSensor
} from '@dnd-kit/core';

import { Card } from './Card';
import { Column } from './Column';
import { LexoRank } from './utils';
import { Actions } from '../Actions';
import { QUERY_KEYS } from '@/utils/query-keys';
import { useActionPlanContext } from '../../context';
import { useUpdateCardRank } from '@/hooks/v2/useUpdateCardRank';
import { useApplicationContext } from '@/context/v1/Application/context';
import type { ActionPlanInfo, Columns, Item } from '@/views/ActionPlans/types';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const initialColumns: Columns = {
	'TO DO': { name: 'Not started', color_primary: 'rgba(47, 84, 235, 1)', color_secondary: 'rgba(234, 238, 253, 1)' },
	DOING: { name: 'Doing', color_secondary: 'rgba(254, 247, 233, 1)', color_primary: 'rgba(240, 174, 0, 1)' },
	DONE: { name: 'Done', color_secondary: 'rgba(23, 169, 59, 0.1)', color_primary: 'rgba(23, 169, 59, 1)' }
};

export function Board() {
	const query = useQuery();
	const lexoRank = new LexoRank();
	const queryClient = useQueryClient();
	const company_id = query.get('company');
	const { organization, company } = useApplicationContext();

	const [doneItems, setDoneItems] = useState<Item[]>([]);
	const [toDoItems, setToDoItems] = useState<Item[]>([]);
	const [doingItems, setDoingItems] = useState<Item[]>([]);
	const [activeId, setActiveId] = useState<string | null>(null);
	const [selectedRows, setSelectedRows] = useState<React.Key[]>();

	const hasSelectedRows = useMemo(() => !!selectedRows && selectedRows.length > 0, [selectedRows]);

	const { onToggleEditModal, selectActionPlan } = useActionPlanContext();
	const [selectedCompanyIds, setSelectedCompanyIds] = useState<string[]>([]);

	const { mutateAsync: updateCardRank } = useUpdateCardRank();

	const isSorted = !!query.get('sort');
	const isFiltered = !!query.get('filter');
	const previousSortRef = React.useRef(isSorted);

	useEffect(() => {
		if (previousSortRef.current !== isSorted) {
			queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_CARD_LIST]);
			previousSortRef.current = isSorted;
		}
	}, [isSorted]);

	const sensors = useSensors(
		useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
		useSensor(TouchSensor, { activationConstraint: { delay: 50, tolerance: 5 } }),
		useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
	);

	function handleItemsChange(status: string, newItems: Item[]) {
		if (status === 'TO DO') setToDoItems(newItems);
		if (status === 'DOING') setDoingItems(newItems);
		if (status === 'DONE') setDoneItems(newItems);
	}

	const handleOnDragStart = (e: DragStartEvent) => {
		setActiveId(e.active.id as string);
	};

	const handleOnDragOver = ({ active, over }: DragOverEvent) => {
		if (!over) return;

		const activeContainer = active.data.current?.containerId;
		const overContainer = over.data.current?.containerId || over.id;

		if (activeContainer === overContainer) return;

		const { newToDoItems, newDoingItems, newDoneItems } = moveItemBetweenColumns(
			toDoItems,
			doingItems,
			doneItems,
			activeContainer,
			overContainer,
			active.id as string
		);

		setToDoItems(newToDoItems);
		setDoingItems(newDoingItems);
		setDoneItems(newDoneItems);
	};

	const handleOnDragEnd = async ({ active, over }: DragEndEvent) => {
		setActiveId(null);
		if (!over) return;

		const activeContainer = active.data.current?.containerId;
		const overContainer = over.data.current?.containerId || over.id;

		const { newToDoItems, newDoingItems, newDoneItems } = moveItemWithinOrBetweenColumns(
			toDoItems,
			doingItems,
			doneItems,
			activeContainer,
			overContainer,
			active.id as string,
			over.id as string
		);

		const movedItem = findMovedItem(overContainer, active.id as string);

		if (movedItem) {
			if (overContainer === 'TO DO') {
				movedItem.lexo_rank = calculateNewRankForMovedItem(toDoItems, movedItem.id);
			} else if (overContainer === 'DOING') {
				movedItem.lexo_rank = calculateNewRankForMovedItem(doingItems, movedItem.id);
			} else {
				movedItem.lexo_rank = calculateNewRankForMovedItem(doneItems, movedItem.id);
			}

			updateCard(movedItem.id, movedItem.lexo_rank, movedItem.status);
		}

		setToDoItems(newToDoItems);
		setDoingItems(newDoingItems);
		setDoneItems(newDoneItems);
	};

	function moveItemBetweenColumns(
		prevToDoItems: Item[],
		prevDoingItems: Item[],
		prevDoneItems: Item[],
		activeContainer: string,
		overContainer: string,
		activeId: string
	) {
		if (
			(activeContainer !== 'TO DO' && activeContainer !== 'DOING' && activeContainer !== 'DONE') ||
			(overContainer !== 'TO DO' && overContainer !== 'DOING' && overContainer !== 'DONE') ||
			(activeContainer === 'TO DO' && !toDoItems.length) ||
			(activeContainer === 'DOING' && !doingItems.length) ||
			(activeContainer === 'DONE' && !doneItems.length)
		) {
			return { newToDoItems: prevToDoItems, newDoingItems: prevDoingItems, newDoneItems: prevDoneItems };
		}

		const activeItems = getItemsByStatus(activeContainer);
		const overItems = getItemsByStatus(overContainer).filter((item) => item !== undefined);

		const [movedItem] = activeItems.filter((item) => item?.id === activeId);

		if (!movedItem) {
			return { newToDoItems: prevToDoItems, newDoingItems: prevDoingItems, newDoneItems: prevDoneItems };
		}

		movedItem.status = overContainer;
		movedItem.lexo_rank = calculateNewRank(overItems, overItems.length);

		const itemAlreadyExists = overItems.some((item) => item?.id === activeId);
		if (itemAlreadyExists) {
			return { newToDoItems: prevToDoItems, newDoingItems: prevDoingItems, newDoneItems: prevDoneItems };
		}

		let newToDoItems = prevToDoItems;
		if (activeContainer === 'TO DO') {
			newToDoItems = activeItems.filter((item) => item?.id !== activeId).filter((item): item is Item => !!item);
		}
		if (overContainer === 'TO DO') {
			newToDoItems = [...overItems, movedItem];
		}

		let newDoingItems = prevDoingItems;
		if (activeContainer === 'DOING') {
			newDoingItems = activeItems.filter((item) => item?.id !== activeId).filter((item): item is Item => !!item);
		}
		if (overContainer === 'DOING') {
			newDoingItems = [...overItems, movedItem];
		}

		let newDoneItems = prevDoneItems;
		if (activeContainer === 'DONE') {
			newDoneItems = activeItems.filter((item) => item?.id !== activeId).filter((item): item is Item => !!item);
		}
		if (overContainer === 'DONE') {
			newDoneItems = [...overItems, movedItem];
		}

		return { newToDoItems, newDoingItems, newDoneItems };
	}

	const moveItemWithinOrBetweenColumns = (
		prevToDoItems: Item[],
		prevDoingItems: Item[],
		prevDoneItems: Item[],
		activeContainer: string,
		overContainer: string,
		activeId: string,
		overId: string
	) => {
		if (
			(activeContainer !== 'TO DO' && activeContainer !== 'DOING' && activeContainer !== 'DONE') ||
			(overContainer !== 'TO DO' && overContainer !== 'DOING' && overContainer !== 'DONE') ||
			(activeContainer === 'TO DO' && !toDoItems.length) ||
			(activeContainer === 'DOING' && !doingItems.length) ||
			(activeContainer === 'DONE' && !doneItems.length)
		) {
			return { newToDoItems: prevToDoItems, newDoingItems: prevDoingItems, newDoneItems: prevDoneItems };
		}

		const activeItems = getItemsByStatus(activeContainer);
		const overItems = getItemsByStatus(overContainer);

		const activeIndex = activeItems.findIndex((item) => item?.id === activeId);
		const overIndex = overItems.findIndex((item) => item?.id === overId);
		const [movedItem] = activeItems.splice(activeIndex, 1);

		if (!movedItem) {
			return { newToDoItems: prevToDoItems, newDoingItems: prevDoingItems, newDoneItems: prevDoneItems };
		}

		movedItem.status = overContainer;

		if (activeContainer === overContainer) {
			activeItems.splice(overIndex, 0, movedItem);
		} else {
			overItems.splice(overIndex, 0, movedItem);
		}

		let newToDoItems = prevToDoItems;
		if (activeContainer === 'TO DO') newToDoItems = activeItems;
		if (overContainer === 'TO DO') newToDoItems = overItems;

		let newDoingItems = prevDoingItems;
		if (activeContainer === 'DOING') newDoingItems = activeItems;
		if (overContainer === 'DOING') newDoingItems = overItems;

		let newDoneItems = prevDoneItems;
		if (activeContainer === 'DONE') newDoneItems = activeItems;
		if (overContainer === 'DONE') newDoneItems = overItems;

		return { newToDoItems, newDoingItems, newDoneItems };
	};

	const calculateNewRank = (items: Item[], index: number) => {
		const prevRank = items[index - 1]?.lexo_rank || '';
		const nextRank = items[index + 1]?.lexo_rank || '';
		const newRank = lexoRank.generate(prevRank, nextRank);
		return newRank;
	};

	const calculateNewRankForMovedItem = (items: Item[], movedItemId: string): string => {
		const index = items.findIndex((item) => item.id === movedItemId);
		const prevRank = index > 0 ? items[index - 1].lexo_rank : '';
		const nextRank = index < items.length - 1 ? items[index + 1].lexo_rank : '';

		const newRank = lexoRank.generate(prevRank, nextRank);
		return newRank;
	};

	const findMovedItem = (containerId: string, itemId: string) => {
		if (containerId === 'TO DO') return toDoItems.find((item) => item?.id === itemId);
		if (containerId === 'DOING') return doingItems.find((item) => item?.id === itemId);
		return doneItems.find((item) => item?.id === itemId);
	};

	const getActiveItem = (): Item | null => {
		let activeItem = toDoItems.find((item) => item?.id === activeId);
		if (activeItem) return activeItem;

		activeItem = doingItems.find((item) => item?.id === activeId);
		if (activeItem) return activeItem;

		activeItem = doneItems.find((item) => item?.id === activeId);
		if (activeItem) return activeItem;

		return null;
	};

	async function updateCard(cardId: string, newRank: string, newStatus: string) {
		try {
			const item = { id: cardId, status: newStatus, lexo_rank: newRank, is_ordered: isSorted || isFiltered };
			const payload = { organization_id: organization.id, company_id: company.id, item };
			await updateCardRank(payload);
		} catch (error: any) {
			const errorMessage = error?.response?.data.message || error.message;
			message.error(I18n.get(errorMessage));
		}
	}

	const handleCardSelect = (cardId: string, checked: boolean, companyId: string) => {
		setSelectedRows((prev = []) => {
			if (checked) {
				setSelectedCompanyIds((prevIds) => [...prevIds, companyId]);
				return [...prev, cardId];
			}
			setSelectedCompanyIds((prevIds) => prevIds.filter((id) => id !== companyId));
			return prev.filter((id) => id !== cardId);
		});
	};

	function canChangeCardResponsible(): boolean {
		if (!selectedCompanyIds.length) {
			return false;
		}
		const firstCompanyId = selectedCompanyIds[0];
		return selectedCompanyIds.every((companyId) => companyId === firstCompanyId);
	}

	function handleOnClickCard(data: ActionPlanInfo): void {
		onToggleEditModal();
		selectActionPlan(data);
	}

	function getItemsByStatus(status: 'TO DO' | 'DOING' | 'DONE') {
		if (status === 'TO DO') return toDoItems;
		if (status === 'DOING') return doingItems;
		return doneItems;
	}

	return (
		<>
			<DndContext
				sensors={sensors}
				onDragEnd={handleOnDragEnd}
				onDragOver={handleOnDragOver}
				onDragStart={handleOnDragStart}
				collisionDetection={closestCorners}
				measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
			>
				<Row gutter={[12, 0]}>
					<Col key={'TO DO'} lg={8}>
						<Column
							status={'TO DO'}
							columns={initialColumns}
							items={toDoItems}
							activeId={activeId}
							onCardClick={handleOnClickCard}
							onItemsChange={handleItemsChange}
							onCardSelect={handleCardSelect}
							selectedRows={selectedRows}
						/>
					</Col>
					<Col key={'DOING'} lg={8}>
						<Column
							status={'DOING'}
							columns={initialColumns}
							items={doingItems}
							activeId={activeId}
							onCardClick={handleOnClickCard}
							onItemsChange={handleItemsChange}
							onCardSelect={handleCardSelect}
							selectedRows={selectedRows}
						/>
					</Col>
					<Col key={'DONE'} lg={8}>
						<Column
							status={'DONE'}
							columns={initialColumns}
							items={doneItems}
							activeId={activeId}
							onCardClick={handleOnClickCard}
							onItemsChange={handleItemsChange}
							onCardSelect={handleCardSelect}
							selectedRows={selectedRows}
						/>
					</Col>
				</Row>
				<DragOverlay>
					{activeId ? <Card id={activeId} item={getActiveItem()!} onClick={handleOnClickCard} /> : null}
				</DragOverlay>
			</DndContext>
			{hasSelectedRows && (
				<Actions
					id={selectedRows || []}
					organizationId={organization.id}
					companyId={company_id ?? company.id}
					canChangeResponsible={canChangeCardResponsible()}
				/>
			)}
		</>
	);
}
