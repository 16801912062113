import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Col, Row, Skeleton, Spin } from 'antd';
import { I18n } from '@aws-amplify/core';

import { CommentsWrapper } from './styles';
import { CommentItem } from './CommentItem';
import { Title } from '@/components/Typography';
import { Refetch } from '@/components/ui/Refetch';
import { PrimaryButton } from '@/views/ActionPlans/styles';
import { CustomFormItem, CustomTextArea } from '../../../Fields/styles';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetCommentsActionPlan } from '@/hooks/v2/useGetCommentsActionPlan';
import { useUpdateCommentsActionPlan } from '@/hooks/v2/useUpdateCommentsActionPlan';
import { useDeleteCommentsActionPlan } from '@/hooks/v2/useDeleteCommentsActionPlan';
import { useCreateCommentsActionPlan } from '@/hooks/v2/useCreateCommentsActionPlan';

interface CommentsProps {
	fieldName: string[];
	actionPlanId: string;
}

export function Comments({ fieldName, actionPlanId }: Readonly<CommentsProps>) {
	const { organization, company } = useApplicationContext();
	const [editingComment, setEditingComment] = useState<{
		commentId: string;
		text: string;
	} | null>(null);

	const [deletingComment, setDeletingComment] = useState<string>('');
	const [newCommentText, setNewCommentText] = useState<string>('');

	const parametersCreate = {
		organization_id: organization?.id!,
		company_id: company.id,
		description: newCommentText,
		action_plan_id: actionPlanId
	};

	const parameters = useMemo(
		() => ({
			organization_id: organization?.id!,
			company_id: company.id,
			action_plan_id: actionPlanId
		}),
		[organization?.id, company.id, actionPlanId]
	);

	const parametersUpdate = {
		organization_id: organization?.id!,
		company_id: company.id,
		description: editingComment?.text,
		action_plan_comment_id: editingComment?.commentId
	};

	const {
		data: dataComments,
		isError: hasErrorComments,
		isLoading: isLoadingComments,
		refetch: refetchComments,
		hasNextPage,
		fetchNextPage,
		isFetchingNextPage
	} = useGetCommentsActionPlan({
		...parameters,
		limit: 4,
		offset: 0
	});

	const { mutateAsync: updateComments } = useUpdateCommentsActionPlan();
	const { mutateAsync: deleteComments } = useDeleteCommentsActionPlan();
	const { mutateAsync: createComments } = useCreateCommentsActionPlan();

	useEffect(() => {
		const handleDelete = async () => {
			const parametersDelete = {
				organization_id: organization?.id!,
				company_id: company.id,
				action_plan_comment_id: deletingComment
			};

			if (deletingComment) {
				await deleteComments(parametersDelete);
			}
		};

		handleDelete();
	}, [organization, company, deletingComment, deleteComments]);

	const allComments = dataComments?.pages.flatMap((page) => page.comments) || [];

	const loadMoreComments = useCallback(() => {
		if (hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [hasNextPage, isFetchingNextPage, fetchNextPage]);

	const handleEditComment = (currentText: string, commentId: string) => {
		const commentToEdit = allComments?.find((comment) => comment.id === commentId);
		if (commentToEdit) {
			setEditingComment({
				commentId,
				text: currentText
			});
		}
	};

	const handleDeleteComments = (commentId: string) => {
		setDeletingComment(commentId);
	};

	const handleSaveComment = async () => {
		await updateComments(parametersUpdate);
		setEditingComment(null);
	};

	const handleAddComment = async () => {
		if (newCommentText.trim()) {
			await createComments(parametersCreate);
			setNewCommentText('');
		}
	};

	const getMenuItems = (commentId: string) => [
		{
			label: (
				<Button
					onClick={() => {
						const comment = allComments?.find((comment) => comment.id === commentId);
						if (comment && comment.description) {
							handleEditComment(comment.description, commentId);
						}
					}}
					type="text"
					icon={<EditOutlined style={{ color: '#2F54EB', fontSize: '25px' }} />}
				/>
			),
			key: '0'
		},
		{
			label: (
				<Button
					onClick={() => handleDeleteComments(commentId)}
					type="text"
					icon={<DeleteOutlined style={{ color: '#E74150', fontSize: '25px' }} />}
				/>
			),
			key: '1'
		}
	];

	if (hasErrorComments) {
		return (
			<Row gutter={[0, 25]}>
				<Col span={24}>
					<Title level={4}>{I18n.get('Comments')}</Title>
				</Col>
				<Col span={24}>
					<Refetch onClick={refetchComments} />
				</Col>
			</Row>
		);
	}

	return (
		<Row gutter={[0, 25]}>
			<Col span={24}>
				<Title level={4}>{I18n.get('Comments')}</Title>
				<Spin spinning={isLoadingComments}>
					{allComments.length > 0 && (
						<CommentsWrapper id={'scrollableDiv' + actionPlanId}>
							<InfiniteScroll
								next={() => loadMoreComments()}
								hasMore={!!hasNextPage}
								dataLength={allComments.length}
								scrollableTarget={'scrollableDiv' + actionPlanId}
								key={allComments.length || actionPlanId}
								style={{ overflow: 'visible' }}
								loader={isFetchingNextPage && <Skeleton.Input active={true} block={true} />}
							>
								{allComments?.map((comment, index) => (
									<CommentItem
										key={comment.id || index}
										comment={comment}
										index={index}
										editingComment={editingComment}
										setEditingComment={setEditingComment}
										handleSaveComment={handleSaveComment}
										getMenuItems={getMenuItems}
										fieldName={fieldName}
									/>
								))}
							</InfiniteScroll>
						</CommentsWrapper>
					)}
				</Spin>
			</Col>
			<Col span={24}>
				<CustomFormItem labelCol={{ span: 24 }}>
					<CustomTextArea
						value={newCommentText}
						placeholder={I18n.get('Comment')}
						onChange={(e) => setNewCommentText(e.target.value)}
						maxLength={500}
						rows={1}
						showCount
					/>
				</CustomFormItem>
			</Col>
			<Col span={24}>
				<PrimaryButton type="primary" onClick={handleAddComment} disabled={!newCommentText.trim()}>
					{I18n.get('Add')}
				</PrimaryButton>
			</Col>
		</Row>
	);
}
