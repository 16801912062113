import styled from 'styled-components';
import { Button } from 'antd';

import {
	DONE_PRIMARY_COLOR,
	TO_DO_PRIMARY_COLOR,
	DOING_PRIMARY_COLOR,
	DONE_SECONDARY_COLOR,
	TO_DO_SECONDARY_COLOR,
	DOING_SECONDARY_COLOR,
	ActionPlansStatus
} from '../../constants';

const colorMapper: Record<string, any> = {
	[ActionPlansStatus.TO_DO]: {
		background: TO_DO_SECONDARY_COLOR,
		color: TO_DO_PRIMARY_COLOR
	},
	[ActionPlansStatus.DOING]: {
		background: DOING_SECONDARY_COLOR,
		color: DOING_PRIMARY_COLOR
	},
	[ActionPlansStatus.DONE]: {
		background: DONE_SECONDARY_COLOR,
		color: DONE_PRIMARY_COLOR
	}
};

interface ContainerProps {
	$status: string;
	$bordered: boolean;
}

export const FilterButton = styled(Button)<ContainerProps>`
	height: 2.4rem;
	font-size: 1rem;
	font-weight: 600;
	padding: 0 0.8rem;
	border-radius: 6px;
	background-color: ${({ $status }) => colorMapper[$status]?.background ?? '#F8F8F8'};
	border-color: ${({ $bordered, $status }) => ($bordered ? colorMapper[$status]?.color ?? '#262626' : 'none')};

	.status-value {
		color: ${({ $status }) => colorMapper[$status]?.color ?? '#262626'};
	}

	:hover {
		border-color: ${({ $status }) => colorMapper[$status]?.color ?? '#262626'};
	}
`;
