import React, { useCallback, useEffect } from 'react';
import { Skeleton } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useDroppable } from '@dnd-kit/core';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import * as S from './styles';
import { Card } from '../Card';
import { Header } from './Header';
import { useActionPlanContext } from '@/views/ActionPlans/context';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetActionPlansCount } from '@/hooks/v2/useGetActionPlanCount';
import { useActionPlanGetCardList } from '@/hooks/v2/useGetActionPlanCardList';
import type { ActionPlanInfo, Columns, Item } from '@/views/ActionPlans/types';

interface ColumnProps {
	items: Item[];
	status: string;
	columns: Columns;
	activeId?: string | null;
	selectedRows?: React.Key[];
	onItemsChange: (status: string, newItems: Item[]) => void;
	onCardClick({ id, company_id }: ActionPlanInfo): void;
	onCardSelect?: (cardId: string, checked: boolean, company_id: string) => void;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export function Column({
	items,
	status,
	columns,
	onItemsChange,
	selectedRows = [],
	onCardSelect,
	onCardClick
}: Readonly<ColumnProps>) {
	const query = useQuery();
	const { filter } = useActionPlanContext();
	const { organization } = useApplicationContext();
	const { setNodeRef } = useDroppable({ id: status, data: { type: 'container', containerId: status } });

	const sort = query.get('sort');
	const { name, color_primary, color_secondary } = columns[status];

	const { data, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } = useActionPlanGetCardList({
		...filter,
		status,
		limit: 10,
		sort: sort ?? undefined,
		origin_name: filter.origin,
		title: filter.action_plan_name,
		organization_id: organization.id,
		due_date_end: filter.due_date?.[1].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		start_date: filter.creation_date?.[0].toDate()
	});

	const { isLoading: isLoadingTotal, data: total } = useGetActionPlansCount({
		...filter,
		organization_id: organization.id,
		title: filter.action_plan_name,
		origin_name: filter.origin,
		status,
		start_date: filter.creation_date?.[0].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		due_date_end: filter.due_date?.[1].toDate()
	});

	useEffect(() => {
		const newItems = data?.pages.flatMap((page) => page.data) || [];
		onItemsChange(status, newItems);
	}, [data?.pages]);

	const loadMore = useCallback(() => {
		if (hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [hasNextPage, isFetchingNextPage, fetchNextPage]);

	if (isError) {
		return <span>{I18n.get('Oops... Something went wrong!')}</span>;
	}

	return (
		<SortableContext id={status} items={items} strategy={verticalListSortingStrategy}>
			<S.Column ref={setNodeRef} color={color_secondary}>
				<Header title={name} total={total?.total} color={color_primary} isLoading={isLoadingTotal} />
				<S.CardContainer id={status}>
					{isLoading && <Skeleton.Input active={true} block={true} />}
					<InfiniteScroll
						loader={<></>}
						next={loadMore}
						hasMore={!!hasNextPage}
						scrollableTarget={status}
						style={{ overflowY: 'hidden' }}
						dataLength={items.length}
					>
						{items.map((card) => (
							<Card
								item={card}
								id={card?.id}
								key={card?.id}
								containerId={status}
								onClick={onCardClick}
								onSelect={onCardSelect}
								isSelected={selectedRows.includes(card?.id)}
							/>
						))}
						{isFetchingNextPage ? <Skeleton.Input active={true} block={true} /> : <></>}
					</InfiniteScroll>
				</S.CardContainer>
			</S.Column>
		</SortableContext>
	);
}
