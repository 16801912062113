import React, { useState, useCallback, useEffect } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { message, Popover } from 'antd';
import { I18n } from '@aws-amplify/core';
import { debounce } from 'lodash';
import ExcelJS from 'exceljs';
import moment from 'moment';

import { useActionPlanContext } from '../../context';

import { Service } from '@/infra/services/action-plan';
import { useApplicationContext } from '@/context/v1/Application/context';
import { useGetActionPlansCount } from '@/hooks/v2/useGetActionPlanCount';

import * as S from './styles';
import { ExportProgress } from './ExportProgress';
import { DEFAULT_COLUMNS } from './utils';
const BATCH_SIZE = 50;

const usePreventResizeObserverError = () => {
	useEffect(() => {
		const originalError = window.console.error;

		window.console.error = (...args) => {
			if (!args.some((arg) => String(arg).includes('ResizeObserver'))) {
				originalError.apply(window.console, args);
			}
		};

		return () => {
			window.console.error = originalError;
		};
	}, []);
};

export function ButtonExportCSV() {
	usePreventResizeObserverError();
	const [isExporting, setIsExporting] = useState(false);
	const [progress, setProgress] = useState(0);
	const { filter, columns } = useActionPlanContext();
	const { organization } = useApplicationContext();

	function getColumns(values: any) {
		if (!values?.length) {
			return DEFAULT_COLUMNS.map(({ dataIndex, title }: any) => ({
				key: dataIndex,
				width: String(title).length + 5,
				header: I18n.get(String(title))
			}));
		}

		return values
			.filter((item: any) => item.key !== 'plus')
			.map(({ dataIndex, title }: any) => ({
				key: dataIndex,
				width: String(title).length + 5,
				header: I18n.get(String(title))
			}));
	}

	const { data: countData } = useGetActionPlansCount({
		...filter,
		origin_name: filter.origin,
		title: filter.action_plan_name,
		organization_id: organization.id,
		due_date_end: filter.due_date?.[1].toDate(),
		end_date: filter.creation_date?.[1].toDate(),
		due_date_start: filter.due_date?.[0].toDate(),
		start_date: filter.creation_date?.[0].toDate()
	});

	const fetchBatch = useCallback(
		async (offset: number, currentBatch: number, totalBatches: number) => {
			try {
				const parameters = {
					...filter,
					offset,
					limit: BATCH_SIZE,
					origin_name: filter.origin,
					title: filter.action_plan_name,
					organization_id: organization.id,
					due_date_end: filter.due_date?.[1].toDate(),
					end_date: filter.creation_date?.[1].toDate(),
					due_date_start: filter.due_date?.[0].toDate(),
					start_date: filter.creation_date?.[0].toDate()
				};

				const { data } = await Service.list(parameters);

				requestAnimationFrame(() => {
					debounce(() => {
						setProgress(Math.round((currentBatch / totalBatches) * 100));
					}, 150)();
				});

				await new Promise((resolve) => setTimeout(resolve, 100));

				return data || [];
			} catch (error) {
				message.error({
					content: I18n.get('Failed to download file'),
					key: 'exportProgress'
				});
				return [];
			}
		},
		[filter, organization.id, setProgress]
	);

	async function handleOnClick() {
		try {
			setIsExporting(true);
			setProgress(0);

			const total = countData?.total || 0;
			const batches = Math.ceil(total / BATCH_SIZE);
			let allRows: any[] = [];

			for (let i = 0; i < batches; i++) {
				const batchData = await fetchBatch(i * BATCH_SIZE, i + 1, batches);
				allRows = [...allRows, ...batchData];
			}

			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet('Action Plans');

			const columnsToUse = columns?.length ? columns : DEFAULT_COLUMNS;

			worksheet.columns = getColumns(columnsToUse);
			worksheet.getRow(1).eachCell((cell) => {
				cell.alignment = { vertical: 'middle', horizontal: 'center' };
				cell.font = { bold: true };
			});

			console.log({ allRows });

			allRows.forEach((row: any) => {
				const rowData = columnsToUse.map((col: any) => {
					if (col.dataIndex === 'responsible' || col.dataIndex === 'author') {
						return row[col.dataIndex]?.name || '';
					}

					if (col.dataIndex === 'tasks') {
						return `${row.completedTasks} / ${row.totalTasks}`;
					}

					if (col.dataIndex === 'progress') {
						return `${row.totalTasks ? (row.completedTasks * 100) / row.totalTasks : 0}%`;
					}

					if (col.dataIndex === 'status' || col.dataIndex === 'priority' || col.dataIndex === 'origin') {
						return I18n.get(row[col.dataIndex]);
					}

					if (
						col.dataIndex === 'completedAt' ||
						col.dataIndex === 'dueDate' ||
						col.dataIndex === 'createdAt'
					) {
						moment.locale(navigator.language);
						return moment(row[col.dataIndex]).isValid() ? moment(row[col.dataIndex]).format('L') : '-';
					}

					return row[col.dataIndex] !== null && row[col.dataIndex] !== undefined
						? String(row[col.dataIndex])
						: '';
				});

				const insertedRow = worksheet.addRow(rowData);
				insertedRow.eachCell((cell) => {
					cell.alignment = { vertical: 'middle', horizontal: 'center' };
				});
			});

			worksheet.eachRow((row) => {
				row.height = 25;
			});

			const buffer = await workbook.xlsx.writeBuffer();
			const blob = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});

			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `action-plans-${moment().format('YYYY-MM-DD')}.xlsx`;
			link.click();
			URL.revokeObjectURL(url);

			message.success({
				content: I18n.get('File sent for download'),
				key: 'exportProgress'
			});
		} catch (error) {
			console.error('Export error:', error);
			message.error({
				content: I18n.get('Failed to download file'),
				key: 'exportProgress'
			});
		} finally {
			setIsExporting(false);
			setProgress(0);
		}
	}

	return (
		<Popover
			open={isExporting}
			content={<ExportProgress progress={progress} />}
			trigger="hover"
			destroyTooltipOnHide
			overlayStyle={{
				pointerEvents: 'none',
				transform: 'scale(1)',
				willChange: 'transform'
			}}
			align={{
				offset: [0, 0]
			}}
		>
			<S.ButtonWrapper onClick={handleOnClick} disabled={isExporting} style={{ position: 'relative' }}>
				<DownloadOutlined />
			</S.ButtonWrapper>
		</Popover>
	);
}
