import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Response } from '@/types';
import { UpdateCardRankResponseDTO } from '@/core/dto';
import { Service } from '@/infra/services/action-plan';
import { QUERY_KEYS } from '@/utils/query-keys';

type Item = {
	is_ordered: boolean;
	lexo_rank: string;
	status: string;
	id: string;
};

interface UpdateCardRankParams {
	organization_id: string;
	company_id: string;
	item: Item;
}

export const useUpdateCardRank = () => {
	const queryClient = useQueryClient();

	return useMutation<UpdateCardRankResponseDTO, AxiosError<Response>, UpdateCardRankParams>(
		(params) => Service.updateCardRank(params),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLANS_COUNT]);
			}
		}
	);
};
