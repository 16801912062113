import { AxiosError } from 'axios';
import type { Response } from '@/types';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/action-plan';
import type { UpdateActionPlanResponseDTO } from '@/core/dto';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface UpdateActionPlanRequest {
	id: string;
	company_id: string;
	organization_id: string;
	title: string;
	description?: string;
	due_date: Date;
	responsible_user_id: string;
	investment_range?: number;
	investment_value?: number;
	priority?: number;
	status?: string;
}

export const useUpdateActionPlan = () => {
	const queryClient = useQueryClient();

	return useMutation<UpdateActionPlanResponseDTO, AxiosError<Response>, UpdateActionPlanRequest>(
		(params) => Service.update(params),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_LIST]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLANS_COUNT]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLAN_CARD_LIST]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_ACTION_PLANS_BY_REPORT]);
			}
		}
	);
};
